import Icon, { DeleteOutlined, OrderedListOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Checkbox, Col, Form, Popover, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import ReactGA from 'react-ga';

import { ReactComponent as iconQuestion } from '@assets/icons/icon-question.svg';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';
import BorderWrapper from '@components/fw/FloatLabel/BorderWrapper';
import FloatCheckbox from '@components/fw/FloatLabel/FloatCheckbox';
import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import RemoteSelect from '@components/fw/RemoteSelect';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import TariffRequestParameters from '@components/fw/TarifDetail/TariffRequestParameters';
import TariffDetailViewerTrack from '@components/fw/TarifDetail/TartiffDetailViewerTrack';
import FreightList from '@components/routed/ClaimInfo/main/FreightList';
import StationSelector from '@components/routed/TariffFgkPage/StationSelector';
import TariffFgkViewer from '@components/routed/TariffFgkPage/TariffFgkViewer';

import designStore from '@stores/designStore/designStore';
import dictService from '@stores/dictStore/dictStore.service';
import exchangeRateStore from '@stores/exchangeRateStore/exchangeRateStore';
import historyTariffInfoStore, {
  TariffRequestWagonGroupDetails,
  defaultTariffRequest,
} from '@stores/historyTariffInfo/historyTariffInfoStore';
import optionsStore from '@stores/optionsStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import { TariffCountryDetailModel } from '@stores/sendsStore/sendData';
import userStore from '@stores/userStore/userStore';

import { showErrorNotify } from '@utils/notify';
import { filterOptionFn } from '@utils/sort';

import OptionsAdditionalChargesViewer from '../TariffResult/OptionsAdditionalChargesViewer';
import OptionsAttributes from '../TariffResult/OptionsAttributes';
import OptionsAttributesPopover from '../TariffResult/OptionsAttributesPopover';
import OptionsCoefficientViewer from '../TariffResult/OptionsCoefficientViewer';
import OptionsPrintBtn from '../TariffResult/OptionsPrintBtn';
import OptionsResultViever from '../TariffResult/OptionsResultViever';
import OptionsResultViewerGroupTotal from '../TariffResult/OptionsResultViewerGroupTotal';
import WegthStepprtResult from '../TariffResult/WegthStepprtResult';
import OptionsProducerBtn from '../producer/OptionsProducerBtn';
import CurrencyDictionary from './CurrencyDictionary';
import OptionsEscortEditor, { countries } from './OptionsEscortEditor';
import OptionsExpeditionModal from './OptionsExpeditionModal';
import OptionsGroupEditor from './OptionsGroupEditor';
import OptionsSizeOverage, { oversizeMax } from './OptionsSizeOverage';
import { stationWithParagraphsTemplate } from './OptionsStationSelectorBtn';

interface State {
  visible: boolean;
}

interface Props {
  name: string;
  label: string;
  form: FormInstance;
}

@observer
class GlobalCountyInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount(): void {
    if (optionsStore.request[this.props.name]) {
      this.setState({ visible: true });
    }
  }

  render() {
    return (
      <Row gutter={{ xs: 0 }}>
        <Col xs={24} lg={12} style={{ height: 32 }}>
          <Checkbox checked={this.state.visible} onChange={(e) => this.onVisibleChange(e.target.checked)}>
            {this.props.label}
          </Checkbox>
        </Col>
        <Col xs={24} lg={12}>
          {this.state.visible && (
            <BorderWrapper height={'32px'} position={'bottom'}>
              <Form.Item name={this.props.name} rules={[{ required: true, message: 'Выберите из списка' }]}>
                <RemoteSelect
                  bordered={false}
                  primitiveResult={true}
                  autoInitDict={true}
                  dataSource={dictService.globalCountries}
                  valueExpr={'countryId'}
                  displayExpr={'shortName'}
                />
              </Form.Item>
            </BorderWrapper>
          )}
        </Col>
      </Row>
    );
  }
  onVisibleChange = (e: boolean) => {
    this.setState({ visible: e });
    this.props.form.setFieldsValue({ [this.props.label]: null });
  };
}

export default GlobalCountyInput;
