import Icon, { DeleteOutlined, OrderedListOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Checkbox, Col, Form, Popover, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import ReactGA from 'react-ga';

import { ReactComponent as iconQuestion } from '@assets/icons/icon-question.svg';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';
import BorderWrapper from '@components/fw/FloatLabel/BorderWrapper';
import FloatCheckbox from '@components/fw/FloatLabel/FloatCheckbox';
import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import RemoteSelect from '@components/fw/RemoteSelect';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import TariffRequestParameters from '@components/fw/TarifDetail/TariffRequestParameters';
import TariffDetailViewerTrack from '@components/fw/TarifDetail/TartiffDetailViewerTrack';
import FreightList from '@components/routed/ClaimInfo/main/FreightList';
import StationSelector from '@components/routed/TariffFgkPage/StationSelector';
import TariffFgkViewer from '@components/routed/TariffFgkPage/TariffFgkViewer';

import designStore from '@stores/designStore/designStore';
import dictService from '@stores/dictStore/dictStore.service';
import exchangeRateStore from '@stores/exchangeRateStore/exchangeRateStore';
import historyTariffInfoStore, {
  TariffRequestWagonGroupDetails,
  defaultTariffRequest,
} from '@stores/historyTariffInfo/historyTariffInfoStore';
import optionsStore from '@stores/optionsStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import { TariffCountryDetailModel } from '@stores/sendsStore/sendData';
import userStore from '@stores/userStore/userStore';

import { showErrorNotify } from '@utils/notify';
import { filterOptionFn } from '@utils/sort';

import OptionsAdditionalChargesViewer from '../TariffResult/OptionsAdditionalChargesViewer';
import OptionsAttributes from '../TariffResult/OptionsAttributes';
import OptionsAttributesPopover from '../TariffResult/OptionsAttributesPopover';
import OptionsCoefficientViewer from '../TariffResult/OptionsCoefficientViewer';
import OptionsPrintBtn from '../TariffResult/OptionsPrintBtn';
import OptionsResultViever from '../TariffResult/OptionsResultViever';
import OptionsResultViewerGroupTotal from '../TariffResult/OptionsResultViewerGroupTotal';
import WegthStepprtResult from '../TariffResult/WegthStepprtResult';
import OptionsProducerBtn from '../producer/OptionsProducerBtn';
import CurrencyDictionary from './CurrencyDictionary';
import OptionsEscortEditor, { countries } from './OptionsEscortEditor';
import OptionsExpeditionModal from './OptionsExpeditionModal';
import OptionsGroupEditor from './OptionsGroupEditor';
import OptionsSizeOverage, { oversizeMax } from './OptionsSizeOverage';
import { stationWithParagraphsTemplate } from './OptionsStationSelectorBtn';

interface State {
  visible: boolean;
}

interface Props {
  form: FormInstance;
}

@observer
class WeightStepper extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount(): void {
    if (optionsStore.request.weightStepperStart || optionsStore.request.weightStepperEnd || optionsStore.request.weightStepperStep) {
      this.setState({ visible: true });
    }
  }

  render() {
    return (
      <div>
        <Checkbox
          style={{ marginTop: 14 }}
          checked={this.state.visible}
          onChange={(e) => this.onWeightStepperVisibleChange(e.target.checked)}>
          Рассчитать с шагом загрузки
        </Checkbox>
        {this.state.visible && (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={24} lg={6} style={{ paddingBottom: '6px' }}>
              <Form.Item name={'weightStepperStart'} rules={[{ required: true, message: 'Укажите загрузку минимальную' }]}>
                <FloatInputNumber placeholder={'Загрузка минимальная'} max={110} min={0.01} step={0.01} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} style={{ paddingBottom: '6px' }}>
              <Form.Item
                name={'weightStepperStep'}
                rules={[
                  { required: true, message: 'Укажите шаг' },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      const start: number = getFieldValue('weightStepperStart');
                      const end: number = getFieldValue('weightStepperEnd');
                      const step: number = value;
                      if (start && step && end) {
                        const dif = end - start;
                        if (dif < 0) {
                          return Promise.reject('Невозможно рассчитать. Проверьте начальный и конечный вес');
                        }
                        if (dif / step > 19) {
                          return Promise.reject('Не более 20 шагов');
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}>
                <FloatInputNumber placeholder={'Шаг веса груза(т)'} max={110} min={0.01} step={0.01} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} style={{ paddingBottom: '6px' }}>
              <Form.Item name={'weightStepperEnd'} rules={[{ required: true, message: 'Укажите загрузку максимальную' }]}>
                <FloatInputNumber placeholder={'Загрузка максимальная'} max={110} min={0.01} step={0.01} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </div>
    );
  }

  onWeightStepperVisibleChange = (e: boolean) => {
    this.setState({ visible: e });
    if (!e) {
      this.props.form.setFieldsValue({ weightStepperStart: null, weightStepperEnd: null, weightStepperStep: null });
    } else {
      this.props.form.setFieldsValue({
        weightStepperStart: null,
        weightStepperEnd: this.props.form.getFieldValue('freightCapacity'),
        weightStepperStep: null,
      });
    }
  };
}

export default WeightStepper;
