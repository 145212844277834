import Icon, { DeleteOutlined, OrderedListOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Checkbox, Col, Form, Popover, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import ReactGA from 'react-ga';

import { ReactComponent as iconQuestion } from '@assets/icons/icon-question.svg';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';
import BorderWrapper from '@components/fw/FloatLabel/BorderWrapper';
import FloatCheckbox from '@components/fw/FloatLabel/FloatCheckbox';
import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import RemoteSelect from '@components/fw/RemoteSelect';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import TariffRequestParameters from '@components/fw/TarifDetail/TariffRequestParameters';
import TariffDetailViewerTrack from '@components/fw/TarifDetail/TartiffDetailViewerTrack';
import FreightList from '@components/routed/ClaimInfo/main/FreightList';
import GlobalCountyInput from '@components/routed/OptionsPage/TariffForm/GlobalCountyInput';
import Kit from '@components/routed/OptionsPage/TariffForm/Kit';
import TariffDistance from '@components/routed/OptionsPage/TariffForm/TariffDistance';
import WeightStepper from '@components/routed/OptionsPage/TariffForm/WeightStepper';
import StationSelector from '@components/routed/TariffFgkPage/StationSelector';
import TariffFgkViewer from '@components/routed/TariffFgkPage/TariffFgkViewer';

import designStore from '@stores/designStore/designStore';
import dictService from '@stores/dictStore/dictStore.service';
import exchangeRateStore from '@stores/exchangeRateStore/exchangeRateStore';
import historyTariffInfoStore, {
  TariffRequestWagonGroupDetails,
  defaultTariffRequest,
} from '@stores/historyTariffInfo/historyTariffInfoStore';
import optionsStore from '@stores/optionsStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import { TariffCountryDetailModel } from '@stores/sendsStore/sendData';
import userStore from '@stores/userStore/userStore';

import { showErrorNotify } from '@utils/notify';
import { filterOptionFn } from '@utils/sort';

import OptionsAdditionalChargesViewer from '../TariffResult/OptionsAdditionalChargesViewer';
import OptionsAttributes from '../TariffResult/OptionsAttributes';
import OptionsAttributesPopover from '../TariffResult/OptionsAttributesPopover';
import OptionsCoefficientViewer from '../TariffResult/OptionsCoefficientViewer';
import OptionsPrintBtn from '../TariffResult/OptionsPrintBtn';
import OptionsResultViever from '../TariffResult/OptionsResultViever';
import OptionsResultViewerGroupTotal from '../TariffResult/OptionsResultViewerGroupTotal';
import WegthStepprtResult from '../TariffResult/WegthStepprtResult';
import OptionsProducerBtn from '../producer/OptionsProducerBtn';
import CurrencyDictionary from './CurrencyDictionary';
import OptionsEscortEditor, { countries } from './OptionsEscortEditor';
import OptionsExpeditionModal from './OptionsExpeditionModal';
import OptionsGroupEditor from './OptionsGroupEditor';
import OptionsSizeOverage, { oversizeMax } from './OptionsSizeOverage';
import { stationWithParagraphsTemplate } from './OptionsStationSelectorBtn';

interface OptionsPageState {
  historyTariffLoading: boolean;
  coeffShowProcess: boolean;
  additionalChargesShowProcess: boolean;
  additionalCharges168ShowProcess: boolean;
  attributesShowProcess: boolean;
  wagonCalcType: 'commonWeight' | 'wagonCount';
  showAdditionalParams: boolean;
  gngOrEtsng: 'etsng' | 'gng' | null;
  weightStepperVisible: boolean;
}

interface Props {
  tariffId?: string;
}

const ETSNG_HELP_TEXT =
  'Выберите первым наименования ЕТСНГ и поиск по ГНГ будет произведен на основе соответствия выбранному наименованию ЕТСНГ';
const GNG_HELP_TEXT =
  'Выберите первым наименования ГНГ и поиск по ЕТСНГ будет произведен на основе соответствия выбранному наименованию ГНГ';

const COLLECTIVE_WAGON_ETSNG = '693227';
const COLLECTIVE_CONTAINER_ETSNG = '693231';

@observer
class OptionsForm extends Component<Props, OptionsPageState> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);
    this.state = {
      historyTariffLoading: false,
      coeffShowProcess: false,
      additionalChargesShowProcess: false,
      additionalCharges168ShowProcess: false,
      attributesShowProcess: false,
      wagonCalcType: null,
      showAdditionalParams: false,
      gngOrEtsng: null,
      weightStepperVisible: false,
    };
  }

  componentDidMount(): void {
    optionsStore.reset();
    optionsStore.getTariffDictionary();
    this.loadExchanges(moment());
    if (this.props.tariffId) {
      this.handleChose(this.props.tariffId);
    }
  }

  handleFormReset = () => {
    optionsStore.toggleStation = false;
    optionsStore.reset();
    this.resetFields();
    this.freightReset();
    this.stationsReset();
    this.gtagProcess('resetFields');
  };

  loadExchanges = (date: any) => {
    exchangeRateStore.load(date);
  };

  onWagonTypeGroupCodeChange = (value) => {
    let request = optionsStore.request;
    request.wagonTypeGroupCode = value;
    //const find = optionsStore.wagonTypesDictionary.find((t) => t.groupCode == value);
    request.wagonTypeCode = null;
    request.scaleShippingTypeCode = value == 4 ? 3 : 2;
    if (value != 1) {
      request.fgk = false;
      request.overloadCategoryCode = '0000';
      this.resetFields(['wagonTypeCode', 'scaleShippingTypeCode', 'fgk']);
    } else {
      this.resetFields(['wagonTypeCode', 'scaleShippingTypeCode']);
    }
  };

  get embedded() {
    return !!this.props.tariffId;
  }

  onWagonTypeCodeChange = (value) => {
    const request = optionsStore.request;
    request.wagonTypeCode = value;
    if (value != 2) {
      request.fgk = false;
      request.oversizeCategoryCode = '0000';
      this.resetFields(['oversizeCategoryCode', 'fgk']);
    } else {
      this.resetFields(['oversizeCategoryCode']);
    }
  };

  resetFields(fields?: string[]) {
    this.forceUpdate(() => {
      if (this.formRef) this.formRef.resetFields(fields);
    });
  }

  freightReset = () => {
    let request = optionsStore.request;
    request.freightEtsngCode = null;
    request.freightGngCode = null;
    request.gngOrEtsng = null;
    this.setState({ gngOrEtsng: null });
    this.resetFields(['freightGngCode', 'freightEtsngCode']);
  };

  stationsReset = () => {
    let request = optionsStore.request;
    request.destStationCode = null;
    request.loadStationCode = null;
    this.resetFields(['destStationCode', 'loadStationCode']);
  };

  onEtsngChange = (value) => {
    let request = optionsStore.request;
    request.freightEtsngCode = value;
    if (value === COLLECTIVE_CONTAINER_ETSNG) {
      this.formRef.setFieldsValue({ scaleShippingTypeCode: 3 });
    }
    if (value === COLLECTIVE_WAGON_ETSNG) {
      this.formRef.setFieldsValue({ scaleShippingTypeCode: 2 });
    }
    if (!this.state.gngOrEtsng || this.state.gngOrEtsng == 'etsng') {
      request.freightGngCode = null;
      this.setState({ gngOrEtsng: 'etsng' });
      this.resetFields(['freightGngCode']);
    }
  };

  onGngChange = (value) => {
    let request = optionsStore.request;
    request.freightGngCode = value;
    if (!this.state.gngOrEtsng || this.state.gngOrEtsng == 'gng') {
      request.freightEtsngCode = null;
      this.setState({ gngOrEtsng: 'etsng' });
      this.resetFields(['freightEtsngCode']);
    }
  };

  coeffOpenProcess = () => {
    this.setState({ coeffShowProcess: true }, () => this.formRef.submit());
    this.gtagProcess('coeff');
  };

  additionalChargesOpenProcess = () => {
    this.setState({ additionalChargesShowProcess: true }, () => this.formRef.submit());
    this.gtagProcess('additionalCharges');
  };
  additionalCharges168OpenProcess = () => {
    this.setState({ additionalCharges168ShowProcess: true }, () => this.formRef.submit());
    this.gtagProcess('additionalCharges168');
  };

  attributesOpenProcess = () => {
    this.setState({ attributesShowProcess: true }, () => this.formRef.submit());
    this.gtagProcess('attributes');
  };

  gtagProcess = (e) => {
    ReactGA.event({
      category: 'Tariff',
      action: e,
    });
  };

  render() {
    const isMobile = designStore.isMobile;
    const isBusy = optionsStore.isBusy;
    const scaleShippingTypeDictionary = optionsStore.scaleShippingType;
    const wagonAffiliationDictionary = optionsStore.wagonAffiliationTypeDictionary;

    const wagonTypesDictionary = optionsStore.wagonTypesDictionary;
    const wagonTypeGroupDictionary = optionsStore.wagonTypeGroupDictionary;
    const quantityShippingType = optionsStore.quantityShippingType;
    const dangerCategoryDictionary = optionsStore.dangerCategoryDictionary;
    const overloadDictionary = optionsStore.overloadDictionary;
    const packagingDictionary = optionsStore.packagingDictionary;
    const speedTypeDictionary = optionsStore.speedTypeDictionary;

    const result = optionsStore.result;
    const request = optionsStore.request;
    const selectedCurrency = optionsStore.selectedCurrency;

    const selectedWagonTypeGroupCode = request.wagonTypeGroupCode;
    const selectedEtsng = request.freightEtsngCode;

    const isCollective = [COLLECTIVE_WAGON_ETSNG, COLLECTIVE_CONTAINER_ETSNG].includes(selectedEtsng);
    const calculateEmptyReturn = this.formRef ? this.formRef.getFieldValue('calculateEmptyReturn') : null;
    const calculateDate = this.formRef ? this.formRef.getFieldValue('calculateDate') : null;
    const overloadCategoryCode = this.formRef ? this.formRef.getFieldValue('overloadCategoryCode') : null;
    const wagonTypeCode = this.formRef ? this.formRef.getFieldValue('wagonTypeCode') : null;
    const oversizeCategoryCode = this.formRef ? this.formRef.getFieldValue('oversizeCategoryCode') : null;
    const oversizeCategoryEnabled = [5, 2].includes(wagonTypeCode) || selectedWagonTypeGroupCode == 6;

    const quantityShippingTypeCode = this.formRef ? this.formRef.getFieldValue('quantityShippingTypeCode') : null;

    const groupDetails: TariffRequestWagonGroupDetails[] = this.formRef ? this.formRef.getFieldValue('groupDetails') : null;
    const groupDetailsEnable = !!groupDetails && !!groupDetails.length;

    const globosoftRequestId = request.globosoftRequestId;
    const fgkEnabled = userStore.canAccess('tariff/fgk') && request.wagonTypeGroupCode == 1 && request.wagonTypeCode == 2;
    const showResult = optionsStore.showResult;
    const title = `Результат расчета ${globosoftRequestId ? '№' + globosoftRequestId : ''} на ${moment(request.calculateDate).format(
      GLOBAL_DATE_FORMAT,
    )}`;

    const selectedGng = request.freightGngCode;
    const gngOrEtsng = this.state.gngOrEtsng;

    return (
      <>
        <ScrolledContainer className={'tariff-container'} style={{ height: 'calc(100vh - var(--header-height) - var(--header-height))' }}>
          <Spin spinning={isBusy || this.state.historyTariffLoading}>
            {!!result && !!result.countryDetails && result.countryDetails.length > 0 && (
              <div className={'result-card'} style={{ display: showResult ? null : 'none' }}>
                <AdaptiveCard
                  bodyStyle={{ padding: isMobile ? '0px' : '16px' }}
                  title={<h1>{title}</h1>}
                  extra={<CollapsedToolbar content={this.topContent()} />}>
                  {showResult && !isMobile && !this.state.historyTariffLoading && <TariffRequestParameters />}
                  {result.groupDetails && result.groupDetails.length ? (
                    <>
                      <OptionsResultViewerGroupTotal
                        tariffDistance={result.groupDetails[0].countryDetail.reduce((acc, curr) => acc + curr.tariffDistance, 0)}
                        deliveryPeriod={result.deliveryPeriod}
                        value={result.groupDetails.reduce((acc: TariffCountryDetailModel[], curr) => acc.concat(curr.countryDetail), [])}
                      />
                      {result.groupDetails.map((gd, index) => {
                        return (
                          <>
                            <h4>
                              Вагон {index + 1}. ВЕС:{gd.freightWeight}, ГРУЗОПОДЪЕМНОСТЬ:{gd.freightCapacity}
                            </h4>
                            <OptionsResultViever
                              countryDetails={gd.countryDetail}
                              showTotal={false}
                              deliveryPeriod={result.deliveryPeriod}
                              emptyDeliveryPeriod={result.emptyDeliveryPeriod}
                              onOpenCoefficients={this.coeffOpenProcess}
                              onOpenAdditionalCharges={this.additionalChargesOpenProcess}
                              onOpenAdditionalCharges168={this.additionalCharges168OpenProcess}
                            />
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <OptionsResultViever
                      countryDetails={result.countryDetails}
                      showTotal={true}
                      deliveryPeriod={result.deliveryPeriod}
                      emptyDeliveryPeriod={result.emptyDeliveryPeriod}
                      onOpenCoefficients={this.coeffOpenProcess}
                      onOpenAdditionalCharges={this.additionalChargesOpenProcess}
                      onOpenAdditionalCharges168={this.additionalCharges168OpenProcess}
                    />
                  )}
                  {result.weightStepperList && result.weightStepperList.length && <WegthStepprtResult />}
                </AdaptiveCard>
              </div>
            )}
            {!!result && !!result.fgk && userStore.canAccess('tariff/fgk') && (
              <AdaptiveCard title={'ФГК'} bodyStyle={{ padding: '0px' }} style={{ minWidth: 975, display: showResult ? null : 'none' }}>
                <TariffFgkViewer response={result.fgk} />
              </AdaptiveCard>
            )}
            {result && (!result.countryDetails || result.countryDetails.length == 0) && (
              <Alert message="Данные не получены" type="warning" />
            )}
            {result && result.errorMessage && <Alert message={result.errorMessage} type="error" />}
            <AdaptiveCard
              bodyStyle={{ padding: isMobile ? null : '16px' }}
              title={<h1>Расчет тарифа</h1>}
              extra={<CollapsedToolbar content={this.topContent()} />}
              style={{ display: showResult ? 'none' : null }}>
              <Form
                layout="vertical"
                ref={(ref) => (this.formRef = ref)}
                name={'tariff'}
                onFinishFailed={() => (optionsStore.showResult = false)}
                initialValues={{
                  ...request,
                  calculateDate: request.calculateDate ? moment(request.calculateDate) : null,
                  currencyDictionary: selectedCurrency.id,
                }}
                onFinish={this.handleConfirm}>
                <Row gutter={{ xs: 0 }}>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="calculateDate">
                      <FloatDatepicker
                        onChange={this.loadExchanges}
                        position={isMobile ? 'top' : 'left'}
                        placeholder={'Дата расчёта'}
                        format={GLOBAL_DATE_FORMAT}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={7} lg={6} xl={6}>
                    <Form.Item>
                      <CurrencyDictionary
                        value={optionsStore.selectedCurrency}
                        position={'middle'}
                        onChange={(e) => {
                          optionsStore.selectedCurrency = e;
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="globosoftRequestId">
                      <FloatInput position={'middle'} readOnly={true} placeholder={'ID расчёта'} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item name="saveTariff" valuePropName={'checked'}>
                      <FloatCheckbox position={isMobile ? 'bottom' : 'right'}>Контроль запроса</FloatCheckbox>
                    </Form.Item>
                  </Col>
                </Row>
                <StationSelector
                  disabled={this.embedded}
                  form={this.formRef}
                  loadName={'loadStationCode'}
                  destName={'destStationCode'}
                  calculateDate={calculateDate}
                />
                <Row gutter={{ xs: 0 }}>
                  <Col xs={24} md={11}>
                    <GlobalCountyInput label={'Страна отправления'} name={'loadCountryCode'} form={this.formRef} />
                  </Col>
                  <Col xs={0} sm={0} md={2} lg={2} xl={2}>
                    {' '}
                  </Col>
                  <Col xs={24} md={11}>
                    <GlobalCountyInput label={'Страна назначения'} name={'destCountryCode'} form={this.formRef} />
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24}>
                    <h4>Маршрут следования/Станции погран переходов</h4>
                    <BorderWrapper height={'adaptive'}>
                      <Form.Item name="borderStations">
                        <RemoteSelect
                          style={{ marginTop: 10 }}
                          bordered={false}
                          mode={'multiple'}
                          itemTemplate={stationWithParagraphsTemplate}
                          placeholder={'Наименование или ж.д. код'}
                          valueExpr={'stationCode'}
                          displayExpr={'fullName'}
                          dataSource={dictService.borderStations}
                        />
                      </Form.Item>
                    </BorderWrapper>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={16}>
                    <h4>Наименование груза</h4>
                  </Col>
                  <Col xs={8}>
                    <Button
                      className={'right'}
                      onClick={this.freightReset}
                      disabled={isBusy}
                      type="link"
                      style={{ marginTop: 20, float: 'left' }}>
                      Очистить
                      <DeleteOutlined />
                    </Button>
                  </Col>
                </Row>
                <Row gutter={{ xs: 0 }}>
                  <Col xs={24} md={12}>
                    <Form.Item name="freightEtsngCode" rules={[{ required: gngOrEtsng == 'etsng', message: 'Необходимо заполнить' }]}>
                      <RemoteSelect
                        position={isMobile ? 'top' : 'left'}
                        float={true}
                        onChange={this.onEtsngChange}
                        placeholder={
                          <span>
                            ЕТСНГ&nbsp;
                            <Popover
                              style={{ width: '200px' }}
                              trigger="hover"
                              content={<div style={{ width: '200px' }}>{ETSNG_HELP_TEXT}</div>}>
                              <Icon component={iconQuestion} style={{ cursor: 'pointer' }} />
                            </Popover>
                          </span>
                        }
                        valueExpr={'freightEtsngCode'}
                        displayExpr={'fullName'}
                        disabled={(gngOrEtsng == 'gng' && !selectedGng) || this.embedded}
                        aValueExpr={gngOrEtsng == 'gng' ? selectedGng : null}
                        dataSource={dictService.freightEtsngDict}
                        primitiveResult={true}
                        autoInitDict={true}
                        itemTemplate={(item) => (
                          <div style={{ whiteSpace: 'normal', height: 'auto' }}>
                            <span style={{ color: 'lightGray' }}>{item['freightEtsngCode']} </span>
                            {item['fullName']}
                          </div>
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item name="freightGngCode" rules={[{ required: gngOrEtsng == 'gng', message: 'Необходимо заполнить' }]}>
                      <RemoteSelect
                        position={isMobile ? 'bottom' : 'right'}
                        float={true}
                        onChange={this.onGngChange}
                        allowClear={true}
                        primitiveResult={true}
                        placeholder={
                          <div className="placeholder-gng">
                            <p style={{ color: '#FF0000' }}>ГНГ (для перевозок по рф заполнять не обязательно)&nbsp;</p>
                            <Popover
                              style={{ width: '200px' }}
                              trigger="hover"
                              content={<div style={{ width: '200px' }}>{GNG_HELP_TEXT}</div>}>
                              <Icon component={iconQuestion} style={{ cursor: 'pointer', height: 24 }} />
                            </Popover>
                          </div>
                        }
                        aValueExpr={gngOrEtsng == 'etsng' ? selectedEtsng : null}
                        valueExpr={'gngCode'}
                        displayExpr={'fullName'}
                        disabled={(gngOrEtsng == 'etsng' && !selectedEtsng) || this.embedded}
                        autoInitDict={true}
                        dataSource={dictService.getFreightGngDictionary}
                        itemTemplate={(item) => (
                          <div style={{ whiteSpace: 'normal', height: 'auto' }}>
                            <span style={{ color: 'lightGray' }}>{item['gngCode']} </span>
                            {item['fullName']}
                          </div>
                        )}
                      />
                    </Form.Item>
                  </Col>
                  {isCollective && (
                    <Col xs={24}>
                      <h4>Сборные грузы</h4>
                      <FreightList
                        primitiveResult={true}
                        form={this.formRef}
                        arrayName={'collectiveShipmentFreightList'}
                        gngName={'gngCode'}
                        etsngName={'etsngCode'}
                        weightName={'weight'}
                      />
                    </Col>
                  )}
                </Row>
                <h4>Группа подвижного состава</h4>
                <Row gutter={{ xs: 0 }}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="wagonTypeGroupCode">
                      <FloatSelect
                        position={isMobile ? 'top' : 'top-left'}
                        disabled={this.embedded}
                        onChange={this.onWagonTypeGroupCodeChange}
                        placeholder="Тип">
                        {wagonTypeGroupDictionary.map((item, index) => {
                          return (
                            <Select.Option value={item.groupCode} key={`wagonTypesCategory_${index}`}>
                              {item.groupName}
                            </Select.Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="wagonAffiliationTypeCode">
                      <FloatSelect position={'middle'} placeholder="Принадлежность" disabled={this.embedded}>
                        {wagonAffiliationDictionary.map((item, index) => {
                          return (
                            <Select.Option value={item.affiliationCode} key={`wagonAffiliation_${index}`}>
                              {item.affiliationName}
                            </Select.Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="globosoftCountryCode">
                      <FloatSelect position={isMobile ? 'middle' : 'top-right'} placeholder={'Гос-во собственник'}>
                        {countries.map((item, index) => {
                          return (
                            <Select.Option value={item.code} key={`countryCode${index}`}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="wagonTypeCode">
                      <FloatSelect
                        position={'middle'}
                        disabled={this.embedded}
                        onChange={this.onWagonTypeCodeChange}
                        placeholder="Подвижной состав"
                        dropdownMatchSelectWidth={false}
                        showSearch
                        filterOption={filterOptionFn}>
                        {wagonTypesDictionary
                          .filter((r) => !selectedWagonTypeGroupCode || r.groupCode == selectedWagonTypeGroupCode)
                          .map((item, index) => {
                            return (
                              <Select.Option value={item.wagonTypeCode} key={`speedCategory_${index}`}>
                                {item.wagonTypeName}
                              </Select.Option>
                            );
                          })}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      extra={
                        <Form.Item name={'groupDetails'}>
                          <OptionsGroupEditor disabled={quantityShippingTypeCode === 0} onChange={this.onGroupDetailsChange} />
                        </Form.Item>
                      }
                      name="quantityShippingTypeCode"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (getFieldValue('wagonCount') > 1 && value == 0) {
                              return Promise.reject('Количество ваг/конт не соответствует типу отправки');
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}>
                      <FloatSelect
                        placeholder="Вид отправки"
                        position={'middle'}
                        onChange={(e) => this.onQuantityShippingTypeCodeChange(e)}>
                        {quantityShippingType.map((item, index) => (
                          <Select.Option value={item.shippingTypeCode} key={`shippingType_${index}`}>
                            {item.shippingTypeName}
                          </Select.Option>
                        ))}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <Form.Item name="scaleShippingTypeCode">
                      <FloatSelect disabled={isCollective} position={'middle'} placeholder="Вид отправки">
                        {scaleShippingTypeDictionary.map((item, index) => (
                          <Select.Option value={item.shippingTypeCode} key={`scaleShippingType_${index}`}>
                            {item.shippingTypeName}
                          </Select.Option>
                        ))}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Form.Item
                      name="freightWeight"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (getFieldValue('commonWeight') && !value) {
                              return Promise.reject('Укажите вес в 1 ваг/конт');
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}>
                      <FloatInputNumber
                        disabled={this.embedded || groupDetailsEnable}
                        position={isMobile ? 'middle' : 'bottom-left'}
                        placeholder="Вес груза в 1 ваг/конт (тонн)"
                        onChange={() => this.onFreightWeightChange()}
                        step={0.001}
                        min={0}
                        max={250}
                        decimalSeparator={'.'}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Form.Item name={'commonWeight'}>
                      <FloatInputNumber
                        position={'middle'}
                        disabled={this.embedded || groupDetailsEnable}
                        placeholder={'Общий вес (тонн)'}
                        step={0.001}
                        min={0}
                        onChange={() => this.onCommonWeightChange()}
                        max={250000}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Form.Item name="wagonCount">
                      <FloatInputNumber
                        disabled={this.embedded || groupDetailsEnable}
                        position={'middle'}
                        placeholder={
                          <span style={{ display: 'inline-block' }}>
                            Количество ваг/конт&nbsp;
                            <Popover
                              style={{ width: '250px' }}
                              trigger="hover"
                              content={
                                <div style={{ width: '250px' }}>
                                  Значение вычисляется автоматический путем округления в большую сторону результата деления{' '}
                                  <b>общего веса</b> на <b>Вес груза в 1 ваг/конт</b>
                                </div>
                              }>
                              <Icon component={iconQuestion} />
                            </Popover>
                          </span>
                        }
                        onChange={() => this.onWagonCountChange()}
                        step={1}
                        min={1}
                        max={9999}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    {selectedWagonTypeGroupCode !== 4 && (
                      <Form.Item name="freightCapacity">
                        <FloatInputNumber
                          disabled={groupDetailsEnable}
                          position={isMobile ? 'bottom' : 'bottom-right'}
                          placeholder="Грузоподъемность"
                          step={1}
                          min={1}
                          max={132}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Row gutter={{ xs: 0 }} style={{ paddingTop: '24px' }}>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Form.Item label="" name={'calculateEmptyReturn'} valuePropName={'checked'}>
                      <Checkbox disabled={this.embedded} onChange={() => this.forceUpdate()}>
                        Расчет порожнего возврата
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                    <Form.Item label="" name="emptyBool" valuePropName={'checked'}>
                      <Checkbox disabled={this.embedded}>Порожний</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                    {userStore.canAccess('tariff/fgk') && (
                      <Form.Item label="" name="fgk" valuePropName={'checked'}>
                        <Checkbox disabled={!fgkEnabled || this.embedded}>
                          <span>
                            Расчет ФГК
                            <Popover
                              style={{ width: '250px' }}
                              trigger="hover"
                              content={
                                <div style={{ width: '250px' }}>
                                  Доступен при выборе Тип: универсальный и Подвижной состав: Полувагон универсальный
                                </div>
                              }>
                              <Button type={'text'} icon={<Icon component={iconQuestion} />} />
                            </Popover>
                          </span>
                        </Checkbox>
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Row gutter={{ xs: 0 }}>
                  {calculateEmptyReturn ? (
                    <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                      <Form.Item name="emptyReturnStation">
                        <RemoteSelect
                          disabled={this.embedded}
                          float={true}
                          placeholder={'Станция назначения порожняка'}
                          itemTemplate={stationWithParagraphsTemplate}
                          valueExpr={'stationCode'}
                          displayExpr={'stationName'}
                          dataSource={dictService.stationWithParagraphDict}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col xs={0} sm={0} md={0} lg={2} xl={8}>
                      {' '}
                    </Col>
                  )}
                </Row>
                <Row gutter={{ xs: 0 }}>
                  <Col xs={24}>
                    <Button
                      className={'blue-gray-button'}
                      block={true}
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        this.setState({ showAdditionalParams: !this.state.showAdditionalParams });
                      }}>
                      Дополнительные параметры
                    </Button>
                  </Col>
                </Row>
                <Row gutter={{ xs: 0 }} style={{ display: this.state.showAdditionalParams ? null : 'none' }}>
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <Form.Item name="overloadCategoryCode">
                      <FloatSelect
                        position={isMobile ? 'top' : 'top-left'}
                        placeholder="Перегрузка"
                        allowClear
                        onChange={() => this.handleOverloadChange()}>
                        {overloadDictionary.map((item, index) => {
                          return (
                            <Select.Option value={item.overloadCode} key={`speedCategory_${index}`}>
                              {item.overloadName}
                            </Select.Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <Form.Item name={'oversizeCategoryCode'}>
                      <OptionsSizeOverage disabled={!oversizeCategoryEnabled} onChange={() => this.forceUpdate()} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <Form.Item name="dangerCategoryCode">
                      <FloatSelect placeholder="Опасность" allowClear position={'middle'}>
                        {dangerCategoryDictionary.map((item, index) => {
                          return (
                            <Select.Option value={item.categoryCode} key={`dangerCategory_${index}`}>
                              {item.categoryName}
                            </Select.Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <Form.Item name="speedTypeCode">
                      <FloatSelect placeholder="Скорость" allowClear position={isMobile ? 'middle' : 'top-right'}>
                        {speedTypeDictionary.map((item, index) => {
                          return (
                            <Select.Option value={item.speedCode} key={`speedCategory_${index}`}>
                              {item.speedName}
                            </Select.Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <Form.Item name="packagingCode">
                      <FloatSelect
                        position={isMobile ? 'middle' : 'bottom-left'}
                        placeholder="Упаковка"
                        allowClear
                        disabled={overloadCategoryCode !== 1}>
                        {packagingDictionary.map((item, index) => {
                          return (
                            <Select.Option value={item.packagingCode} key={`packagingCategory_${index}`}>
                              {item.packagingName}
                            </Select.Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <Form.Item>
                      <FloatInput placeholder="Максимальная" readOnly position={'middle'} value={oversizeMax(oversizeCategoryCode).name} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <Form.Item name="axesCount">
                      <FloatInputNumber placeholder="Оси" position={'middle'} step={1} min={0} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <Form.Item name="couplerWagonCount">
                      <FloatInputNumber placeholder="Сцепка" position={isMobile ? 'bottom' : 'bottom-right'} step={1} min={0} />
                    </Form.Item>
                  </Col>
                </Row>
                {this.state.showAdditionalParams && !this.state.historyTariffLoading && (
                  <Row gutter={{ xs: 0 }}>
                    <Col span={24}>
                      <Form.Item name={['tariffData', 'escort']}>
                        <OptionsEscortEditor />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <WeightStepper form={this.formRef} />
                    </Col>
                    <Col span={24}>
                      <Kit form={this.formRef} />
                    </Col>
                    <Col span={24}>
                      <TariffDistance form={this.formRef} />
                    </Col>
                  </Row>
                )}
              </Form>
            </AdaptiveCard>
          </Spin>
        </ScrolledContainer>
        <AdaptivePageHeader
          extra={[
            !showResult ? (
              <Button
                key={'reset_btn'}
                form={'claim-editor'}
                onClick={this.handleFormReset}
                disabled={isBusy}
                type={'link'}
                size={isMobile ? 'small' : 'middle'}
                style={{ float: 'left' }}>
                Очистить все <DeleteOutlined />
              </Button>
            ) : null,
            !showResult ? (
              <Button
                style={{ marginLeft: 10, marginRight: 10 }}
                key={'result'}
                size={isMobile ? 'small' : 'large'}
                type={'primary'}
                onClick={() => (optionsStore.showResult = true)}
                disabled={!result || !result.countryDetails || !result.countryDetails.length}>
                Показать результат
              </Button>
            ) : null,
            !showResult ? (
              <Button
                onClick={() => {
                  optionsStore.request = defaultTariffRequest();
                }}
                key={'submit_btn'}
                type="primary"
                htmlType="submit"
                form={'tariff'}
                disabled={isBusy}
                size={isMobile ? 'small' : 'large'}
                loading={isBusy}>
                Рассчитать
              </Button>
            ) : null,
            showResult ? (
              <Button type={'primary'} key={'close'} onClick={() => (optionsStore.showResult = false)}>
                Закрыть
              </Button>
            ) : null,
          ]}
        />
        {optionsStore.showAttributesModal && <OptionsAttributes />}
        {optionsStore.showCoefficient && <OptionsCoefficientViewer />}
        {optionsStore.showAdditionalCharges && <OptionsAdditionalChargesViewer type={1} />}
        {optionsStore.showAdditionalCharges168 && <OptionsAdditionalChargesViewer type={168} />}
        {optionsStore.showExpedition && <OptionsExpeditionModal />}
      </>
    );
  }

  onFreightWeightChange = () => {
    if (this.state.wagonCalcType == 'commonWeight') {
      this.onCommonWeightChange();
    } else if (this.state.wagonCalcType == 'wagonCount') {
      this.onWagonCountChange();
    } else {
    }
    if (this.formRef) {
      this.formRef.validateFields(['freightWeight']);
    }
  };

  onCommonWeightChange = () => {
    if (this.formRef) {
      this.setState({ wagonCalcType: 'commonWeight' });
      const commonWeight = this.formRef.getFieldValue('commonWeight');
      const freightWeight = this.formRef.getFieldValue('freightWeight');
      if (commonWeight && freightWeight) {
        optionsStore.request.wagonCount = Math.ceil(commonWeight / freightWeight);
        this.forceUpdate(() => {
          this.formRef.resetFields(['wagonCount']);
          this.formRef.validateFields(['quantityShippingTypeCode']);
        });
      } else {
        this.formRef.validateFields(['commonWeight', 'freightWeight', 'quantityShippingTypeCode']);
      }
    }
  };

  onGroupDetailsChange = (groupDetails: TariffRequestWagonGroupDetails[]) => {
    const groupDetailsEnable = !!groupDetails && !!groupDetails.length;
    if (groupDetailsEnable) {
      const groupCount = groupDetailsEnable ? groupDetails.length : null;
      const groupSummaryWeight = groupDetailsEnable ? groupDetails.reduce((sum, current) => sum + current.freightWeight, 0) : null;
      const groupSummaryCapacity = groupDetailsEnable ? groupDetails.reduce((sum, current) => sum + current.freightCapacity, 0) : null;
      const groupAvgWeight = groupDetailsEnable ? Math.round(groupSummaryWeight / groupCount) : null;
      const groupAvgCapacity = groupDetailsEnable ? Math.round(groupSummaryCapacity / groupCount) : null;
      this.formRef.setFieldsValue({
        wagonCount: groupCount,
        commonWeight: groupSummaryWeight,
        freightWeight: groupAvgWeight,
        freightCapacity: groupAvgCapacity,
      });
    }
    this.forceUpdate();
  };

  onQuantityShippingTypeCodeChange = (e: number) => {
    if (e == 0) {
      this.formRef.setFieldsValue({ groupDetail: null });
    }
    this.forceUpdate();
  };

  onWagonCountChange = () => {
    if (this.formRef) {
      this.setState({ wagonCalcType: 'wagonCount' });
      const wagonCount = this.formRef.getFieldValue('wagonCount');
      const freightWeight = this.formRef.getFieldValue('freightWeight');
      if (wagonCount && freightWeight) {
        optionsStore.request.commonWeight = wagonCount * freightWeight;
        this.forceUpdate(() => {
          this.formRef.resetFields(['commonWeight']);
          this.formRef.validateFields(['quantityShippingTypeCode']);
        });
      } else {
        this.formRef.validateFields(['wagonCount', 'freightWeight', 'quantityShippingTypeCode']);
      }
    }
  };

  handleConfirm = (event) => {
    Object.keys(event).forEach((key) => {
      if (key == 'emptyBool') optionsStore.request.empty = event[key] ? 1 : 0;
      if (key == 'tariffData' && event['tariffData']) {
        Object.keys(event['tariffData']).forEach((tariffDataKey) => {
          optionsStore.request.tariffData[tariffDataKey] = event['tariffData'][tariffDataKey];
        });
      }
      optionsStore.request[key] = event[key];
    });
    if (optionsStore.toggleStation) {
      const load = optionsStore.request.loadStationCode;
      const dest = optionsStore.request.destStationCode;
      optionsStore.request.loadStationCode = dest;
      optionsStore.request.destStationCode = load;
    }
    if (!optionsStore.request.tariffData) {
      optionsStore.request.tariffData = {
        borderStationCodes: null,
        escort: null,
        attributes: null,
        coefficients: null,
        questions: null,
        additionalCharges: null,
      };
    }
    optionsStore.request.tariffData.borderStationCodes = event.borderStations;
    optionsStore.request.calculateDate = moment(optionsStore.request.calculateDate).toISOString(true);
    if (this.state.coeffShowProcess) {
      this.setState({ coeffShowProcess: false });
      optionsStore.showCoefficient = true;
      return;
    }
    if (this.state.additionalChargesShowProcess) {
      this.setState({ additionalChargesShowProcess: false });
      optionsStore.showAdditionalCharges = true;
      return;
    }
    if (this.state.additionalCharges168ShowProcess) {
      this.setState({ additionalCharges168ShowProcess: false });
      optionsStore.showAdditionalCharges168 = true;
      return;
    }

    if (this.state.attributesShowProcess) {
      this.setState({ attributesShowProcess: false });
      optionsStore.showAttributesModal = true;
      return;
    }

    optionsStore.request.gngOrEtsng = this.state.gngOrEtsng;

    optionsStore
      .calcTariff()
      .then(() => {
        this.resetFields(['borderStations', 'globosoftRequestId']);
      })
      .finally(() => {
        this.gtagProcess('submit');
      });
  };

  async handleChose(tariffRequestId) {
    if (!tariffRequestId) {
      showErrorNotify('Невозможно загрузить', 'Не задан ID расчета');
      return;
    }
    this.setState({ historyTariffLoading: true });
    try {
      const ans = await historyTariffInfoStore.getHistoryTariffInfo(tariffRequestId);
      if (ans.response) {
        if (ans.request) {
          if (ans.request.calculateDate) ans.request.calculateDate = moment(ans.request.calculateDate);
          this.loadExchanges(ans.request.calculateDate);
        }
        optionsStore.request = ans.request;
        optionsStore.result = ans.response;
        optionsStore.request.emptyBool = !!optionsStore.request.empty;
        if (optionsStore.request.tariffData && optionsStore.request.tariffData.borderStationCodes) {
          optionsStore.request.borderStations = optionsStore.request.tariffData.borderStationCodes;
        }
        if (optionsStore.request.freightWeight && optionsStore.request.wagonCount)
          optionsStore.request.commonWeight = optionsStore.request.freightWeight * optionsStore.request.wagonCount;
        if (optionsStore.request.weightStepperStart || optionsStore.request.weightStepperEnd || optionsStore.request.weightStepperStep) {
          this.setState({ showAdditionalParams: true });
        }
        if (optionsStore.request.tariffData && optionsStore.request.tariffData.escort && optionsStore.request.tariffData.escort.length) {
          this.setState({ showAdditionalParams: true });
        }
        this.resetFields();
        this.setState({ gngOrEtsng: optionsStore.request.gngOrEtsng });
      }
    } finally {
      optionsStore.toggleStation = false;
      this.setState({ historyTariffLoading: false });
    }
  }

  handleOverloadChange = () => {
    const request = optionsStore.request;
    request.packagingCode = null;
    this.resetFields(['packagingCode']);
  };

  topContent = () => {
    const isBusy = optionsStore.isBusy;
    const result = optionsStore.result;
    const isMobile = designStore.isMobile;
    const showResult = optionsStore.showResult;
    const arr = [
      <OptionsAttributesPopover key={'attributes'}>
        <Badge
          style={{ fontSize: 8 }}
          size={'small'}
          offset={['-90px', '10px']}
          showZero={false}
          count={optionsStore.enabledAttributes.length}>
          <Button
            icon={<OrderedListOutlined />}
            type={'link'}
            onClick={this.attributesOpenProcess}
            disabled={isBusy || !result || !result.tariffDetailData}>
            Признаки
          </Button>
        </Badge>
      </OptionsAttributesPopover>,
    ];
    if (!showResult && isMobile) {
      arr.push(
        <Button
          key={'result'}
          type={'link'}
          onClick={() => (optionsStore.showResult = true)}
          disabled={!result || !result.countryDetails || !result.countryDetails.length}>
          Показать результат
        </Button>,
      );
      arr.push(
        <Button
          key={'result'}
          type={'link'}
          onClick={() => (optionsStore.showResult = true)}
          disabled={!result || !result.countryDetails || !result.countryDetails.length}>
          Показать результат
        </Button>,
      );
      arr.push(
        <Button
          onClick={() => {
            optionsStore.request = defaultTariffRequest();
          }}
          key={'submit_btn'}
          type={'link'}
          htmlType="submit"
          form={'tariff'}
          disabled={isBusy}
          loading={isBusy}>
          Рассчитать
        </Button>,
      );
    }
    if (showResult && isMobile) {
      arr.push(
        <Button key={'close'} type={'link'} onClick={() => (optionsStore.showResult = false)}>
          Закрыть результат
        </Button>,
      );
    }
    arr.push(<TariffDetailViewerTrack key={'trackBtn'} />);
    arr.push(<OptionsProducerBtn key={'OptionsProcedureBtn'} />);
    arr.push(<OptionsPrintBtn key={'printBtn'}>Распечатать</OptionsPrintBtn>);

    return arr;
  };
}

export default OptionsForm;
