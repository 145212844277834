import Icon from '@ant-design/icons';
import { Checkbox, Col, Form, Popover, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { ReactComponent as iconQuestion } from '@assets/icons/icon-question.svg';

import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import optionsStore from '@stores/optionsStore';

import { countries } from './OptionsEscortEditor';

interface State {
  visible: boolean;
}

interface Props {
  form: FormInstance;
}

const HELP_TEXT = 'Укажите тарифное расстояние для уточнения расстояния перевозки по одной из стран маршрута';

@observer
class TariffDistance extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount(): void {
    if (optionsStore.request.tariffDistance || optionsStore.request.tariffDistanceCountryCode) {
      this.setState({ visible: true });
    }
  }

  render() {
    return (
      <div>
        <Checkbox style={{ marginTop: 14 }} checked={this.state.visible} onChange={(e) => this.onVisibleChange(e.target.checked)}>
          Тарифное расстояние{' '}
          <Popover style={{ width: '200px' }} trigger="hover" content={<div style={{ width: '200px' }}>{HELP_TEXT}</div>}>
            <Icon component={iconQuestion} style={{ cursor: 'pointer', height: 24 }} />
          </Popover>
        </Checkbox>
        {this.state.visible && (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={24} lg={12} style={{ paddingBottom: '6px' }}>
              <Form.Item name={'tariffDistance'} rules={[{ required: true, message: 'Укажите расстояние' }]}>
                <FloatInputNumber min={0} max={40000} step={1} placeholder={'Расстояние'} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} style={{ paddingBottom: '6px' }}>
              <Form.Item name={'tariffDistanceCountryCode'} rules={[{ required: true, message: 'Укажите гос-во' }]}>
                <FloatSelect placeholder={'Гос-во'}>
                  {countries.map((item, index) => {
                    return (
                      <Select.Option value={item.code} key={`tariffDistanceCountryCode${index}`}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </FloatSelect>
              </Form.Item>
            </Col>
          </Row>
        )}
      </div>
    );
  }

  onVisibleChange = (e: boolean) => {
    this.setState({ visible: e });
    this.props.form.setFieldsValue({ tariffDistance: null, tariffDistanceCountryCode: null });
  };
}

export default TariffDistance;
