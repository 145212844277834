import { Checkbox, Col, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import FloatInput from '@components/fw/FloatLabel/FloatInput';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import optionsStore from '@stores/optionsStore';

import { countries } from './OptionsEscortEditor';

interface State {
  visible: boolean;
}

interface Props {
  form: FormInstance;
}

@observer
class Kit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount(): void {
    if (optionsStore.request.kitNumber || optionsStore.request.kitCountryCode) {
      this.setState({ visible: true });
    }
  }

  render() {
    return (
      <div>
        <Checkbox style={{ marginTop: 14 }} checked={this.state.visible} onChange={(e) => this.onVisibleChange(e.target.checked)}>
          Код исключительного тарифа
        </Checkbox>
        {this.state.visible && (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={24} lg={12} style={{ paddingBottom: '6px' }}>
              <Form.Item name={'kitNumber'} rules={[{ required: true, message: 'Укажите номер тарифа' }]}>
                <FloatInput placeholder={'Номер тарифа'} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} style={{ paddingBottom: '6px' }}>
              <Form.Item name={'kitCountryCode'} rules={[{ required: true, message: 'Укажите гос-во тарифа' }]}>
                <FloatSelect placeholder={'Гос-во тарифа'}>
                  {countries.map((item, index) => {
                    return (
                      <Select.Option value={item.code} key={`kitCountryCode${index}`}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </FloatSelect>
              </Form.Item>
            </Col>
          </Row>
        )}
      </div>
    );
  }

  onVisibleChange = (e: boolean) => {
    this.setState({ visible: e });
    this.props.form.setFieldsValue({ kitNumber: null, kitCountryCode: null });
  };
}

export default Kit;
