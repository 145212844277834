import Icon from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import { Moment } from 'moment';
import React, { Component } from 'react';

import { ReactComponent as change } from '@assets/icons/change.svg';

import BorderWrapper from '@components/fw/FloatLabel/BorderWrapper';
import OptionsStationSelectorBtn from '@components/routed/OptionsPage/TariffForm/OptionsStationSelectorBtn';

import designStore from '@stores/designStore/designStore';
import optionsStore from '@stores/optionsStore';

interface State {}
interface Props {
  disabled: boolean;
  form: FormInstance;
  loadName: string;
  destName: string;
  calculateDate: Moment;
  valueExpr?: string;
}

class StationSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      toggleStation: false,
    };
  }

  handleToggleStation = () => {
    optionsStore.toggleStation = !optionsStore.toggleStation;
  };

  render() {
    const toggleStation = optionsStore.toggleStation;
    const isMobile = designStore.isMobile;
    const { form, loadName, destName, disabled, calculateDate, valueExpr } = this.props;

    return (
      <Row gutter={{ xs: 0 }} style={{ marginTop: 24, position: 'relative' }}>
        <Col xs={24} sm={24} md={11} lg={11} xl={11} style={{ order: !toggleStation ? 0 : 2 }}>
          <OptionsStationSelectorBtn
            position={isMobile ? 'top' : 'top-left'}
            required={true}
            disabled={disabled}
            onChange={(e) => {
              if (!toggleStation) {
                optionsStore.request.loadStationCode = e;
              }
            }}
            name={loadName}
            type={!toggleStation ? 'отправления' : 'назначения'}
            formRef={form}
            valueExpr={valueExpr}
            calculateDate={calculateDate}
          />
        </Col>
        <Col xs={0} sm={0} md={2} lg={2} xl={2} style={{ order: 1, textAlign: 'center' }}>
          <Form.Item>
            <BorderWrapper position={'middle'}>
              <Button
                disabled={disabled}
                icon={<Icon style={{ fontSize: 16 }} component={change} />}
                onClick={this.handleToggleStation}
                type={'text'}
                style={{ color: toggleStation ? 'blue' : 'darkgrey', marginTop: 10 }}
              />
            </BorderWrapper>
          </Form.Item>
        </Col>
        {isMobile && (
          <Button
            disabled={disabled}
            icon={<Icon style={{ fontSize: 16 }} component={change} />}
            onClick={this.handleToggleStation}
            type={'text'}
            style={{
              zIndex: 1000,
              background: 'white',
              color: toggleStation ? 'blue' : 'darkgrey',
              position: 'absolute',
              top: 35,
              right: 5,
              transform: 'rotate(90deg)',
            }}
          />
        )}
        <Col xs={24} sm={24} md={11} lg={11} xl={11} style={{ order: !toggleStation ? 2 : 0 }}>
          <OptionsStationSelectorBtn
            required={true}
            position={isMobile ? 'bottom' : 'top-right'}
            disabled={disabled}
            onChange={(e) => {
              if (toggleStation) {
                optionsStore.request.loadStationCode = e;
              }
            }}
            name={destName}
            type={!toggleStation ? 'назначения' : 'отправления'}
            formRef={form}
            valueExpr={valueExpr}
            calculateDate={calculateDate}
          />
        </Col>
      </Row>
    );
  }
}

export default observer(StationSelector);
